const baseUrl = window.location.host === 'thetradedesk-connector.narrative.io' ?
  'https://thetradedesk.narrativeconnectors.com' :
  'https://thetradedesk-dev.narrativeconnectors.com'
// const baseUrl = "http://localhost:9555"

let headers = null

function setHeaders(token) {
	headers = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}
}

function getHeaders() {
	return headers
}

export {
	baseUrl,
	setHeaders,
	getHeaders
}
